import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import noMember from '../img/NoMember.png';

const homePage = (
  <div className="text-center">
    <div className="section-title">
      Welcome to the Counter Strike: Global Offensive Page
    </div>
    <div className="fontBigger">
      Our CSGO community is most reputable for the collegiate team we produce. It is ranked as one of the top 10 collegiate CS:GO teams in the country.
      The team competes in three collegiate tournaments throughout the year, two $10,000 tournaments and a $40,000 tournament.
      <br />
      <br />
      We meet every other week in Torgersen Hall. Come join us and play some custom games with other club members, socialize, and most importantly, have fun!
    </div>
  </div>
);

const recruitPage = (
  <div>
    <Row>
      <Col>
        <div className="text-center section-titleSmaller">
          CSGO Player Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the collegiate team, there are responsibilites we will hold you accountable for which includes:
          </div>
          <br />
          <div>
            - Being on time to games and scrims. Do not show up the minute right before.
          </div>
          <br />
          <div>
            - Being able to attend in-person vod reviews (not all but at least some).
          </div>
          <br />
          <div>
            - Not being toxic. Not only do you need to be a good teammmate, you will be representing the club.
          </div>
          <br />
          <div>
            - Being able to communicate in and out of game. Effective communication is what will make the team better
          </div>
          <br />
          <div>
            - Having a growth mindset. An improvement mentality is a must on the team.
          </div>
          <br />
          <div className="interested">
            <div>
              If interested, contact Jon Cheatwood:
            </div>
            <div>
              Email - jcheat14@vt.edu
            </div>
            <div>
              Steam - <a className="purpleFont" href="https://steamcommunity.com/id/M16man66" target="_blank" rel="noopener noreferrer">Click Here!</a>
            </div>
            <div>
              Discord - M16man66#3429
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div className="text-center section-titleSmaller">
          CSGO Staff Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the staffing team, there are multiple positions available which include:
          </div>
          <br />
          <br />
          <div>
            <b>Analyst</b> - Responsible for keeping track of statistics and maintaining spreadsheets. Preferrably have experience with Excel.
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="interested">
            <div>
              If interested, contact Jon Cheatwood:
            </div>
            <div>
              Email - jcheat14@vt.edu
            </div>
            <div>
              Steam - <a className="purpleFont" href="https://steamcommunity.com/id/M16man66" target="_blank" rel="noopener noreferrer">Click Here!</a>
            </div>
            <div>
              Discord - M16man66#3429
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

const team1 = (
  <div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Remnant
        </div>
          <div className="memberRealname">
          Neil Gutkin
        </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Niise
      </div>
          <div className="memberRealname">
          Ben Mauldin
      </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Ellis
          </div>
          <div className="memberRealname">
            Michael Ellis
          </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Koren
          </div>
          <div className="memberRealname">
            Gavin Ko
          </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Frozen
          </div>
          <div className="memberRealname">
            Alex Dziarnowski
          </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            M16man66
          </div>
          <div className="memberRealname">
            Jon Cheatwood
          </div>
          <div className="memberRole">
            Role:&nbsp;Coach
          </div>
        </div>
      </div>
      <div className="memberNameParagraph">
      </div>
    </div>
  </div>
);



class CSGO extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1, team: 1,
    }
    this.teamPage = this.teamPage.bind(this);
  }

  teamPage() {
    const { team } = this.state;
    return (
      <div>
        <br />
        <Row>
          <div className={`buttonParallel parallelogram ${team === 1 ? 'buttonParallelActive' : ''}`} onClick={() => this.setState({ team: 1 })}>
            <span className="skew-fix">
              Main Roster
            </span>
          </div>
        </Row>
        <br />
        <Row>
          {team === 1 &&
            <div className="teamContainer">
              {team1}
            </div>
          }
        </Row>
      </div>
    );
  }

  render() {
    const { tab } = this.state;

    let content;
    switch (tab) {
      case 1:
        content = homePage;
        break;
      case 2:
        content = this.teamPage();
        break;
      case 3:
        content = recruitPage;
        break;
      default:
        break;
    }

    return (
      <div className="canScroll">
        <div className="whitishBG">
          <div className="sidenav">
            <div className="borderNav">
              <p className={tab === 1 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 1 })}>Home</p>
              <p className={tab === 2 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 2 })}>Collegiate Team</p>
              <p className={tab === 3 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 3 })}>Recruitment</p>
            </div>
          </div>
          <div className="left">
            <Container>
              {content}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default CSGO;