import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function App() {
  return (
    <div className="banner-area relative">
      <div className="overlay overlay-bg" />
      <div className="container" >
        <div className="row fullscreen justify-content-center align-items-center height80">
          <div className="col-lg-8">
            <div className="banner-content text-center">
              <p className="text-uppercase text-white goodFont">where community meets competition meets video games</p>
              <h1 className="text-uppercase text-white goodFont fontSize36">Virginia Esports Association</h1>
              <Link to="/About/" className="primary-btn banner-btn">Explore Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
