import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import noMember from '../img/NoMember.png';

const homePage = (
  <div className="text-center">
    <div className="section-title">
      Welcome to the Overwatch Page
    </div>
    <div className="fontBigger">
      Our Overwatch section is headed by Can Lu and Nelson Chen. They compete in TESPA tournaments and more!
      <br />
      <br />
      We meet every other week in Torgersen Hall. Come join us and play some custom games with other club members, socialize, and most importantly, have fun!
    </div>
  </div>
);

const recruitPage = (
  <div>
    <Row>
      <Col>
        <div className="text-center section-titleSmaller">
          OW Player Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the collegiate team, there are responsibilites we will hold you accountable for which includes:
          </div>
          <br />
          <div>
            - Being on time to games and scrims. Do not show up the minute right before.
          </div>
          <br />
          <div>
            - Being able to attend in-person vod reviews (not all but at least some).
          </div>
          <br />
          <div>
            - Not being toxic. Not only do you need to be a good teammmate, you will be representing the club.
          </div>
          <br />
          <div>
            - Being able to communicate in and out of game. Effective communication is what will make the team better
          </div>
          <br />
          <div>
            - Having a growth mindset. An improvement mentality is a must on the team.
          </div>
          <br />
          <a className="btn maroon-btn" href="https://discord.gg/WRSKJSW" target="_blank" rel="noopener noreferrer">Click Here To Apply!</a>
          <br />
          <br />
          <div>
            *If the link is expired, reach out to Can Lu (contact information to the right)
          </div>
        </div>
      </Col>
      <Col>
        <div className="text-center section-titleSmaller">
          OW Staff Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the staffing team, there are multiple positions available which include:
          </div>
          <br />
          <br />
          <div>
            <b>Coach</b> - Responsible for the overall direction of the team. Must be knowledgable in game and have leadership and communication attributes.
          </div>
          <br />
          <div>
            <b>Analyst</b> - Responsible for keeping track of statistics and maintaining spreadsheets. Preferrably have experience with Excel.
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="interested">
            <div>
              If interested, contact Can Lu:
            </div>
            <div>
              Email - vespaow76@gmail.com
            </div>
            <div>
              Discord - GudSushii#0417
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);





class Overwatch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1, team: 1,
    }
    this.teamPage = this.teamPage.bind(this);
  }

  team1() {
    return (
        <div>
          <div className="fontPicker purpleText">
          Virginia Tech’s Overwatch team is not to be overlooked. As the years have passed, the skill ceiling has risen immensely. 
          With the 2017-2018 team’s SR ranging from High Master to Grandmaster, this team is comprised of the best players that the school has to offer. 
          For the upcoming 2018-2019 school year, we hope to bring in old players who provide a solid backbone for the team, 
          as well as new players who aspire to really show the collegiate scene that we can compete with the best of the best. 
          The team competes in Tespa hosted tournaments, which provides players with a very professional environment to hone their skills and see how they match up against others.
          </div>
          <br />
          <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
              <div className="profileBox">
                <div className="memberTitle">
                  GudSushii
              </div>
                <div className="memberRealname">
                Can Lu
              </div>
              </div>
            </div>
            <div className="memberNameParagraph">
            </div>
          </div>
          <div className="teamMember">
                <div className="pictureWidth">
                  <img src={noMember} alt="member1" className="memberPic" />
                </div>
                <div className="memberName">
                  <div className="profileBox">
                    <div className="memberTitleClickable" onClick={() => this.setState({ tab: 3 })}>
                      Apply Now!
                    </div>
                    <div className="memberRealname">
                      Open Position
                  </div>
                  </div>
                </div>
                <div className="memberNameParagraph">
                Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
                </div>
            </div>
            <div className="teamMember">
                <div className="pictureWidth">
                  <img src={noMember} alt="member1" className="memberPic" />
                </div>
                <div className="memberName">
                  <div className="profileBox">
                    <div className="memberTitleClickable" onClick={() => this.setState({ tab: 3 })}>
                      Apply Now!
                    </div>
                    <div className="memberRealname">
                      Open Position
                  </div>
                  </div>
                </div>
                <div className="memberNameParagraph">
                Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
                </div>
            </div>
            <div className="teamMember">
                <div className="pictureWidth">
                  <img src={noMember} alt="member1" className="memberPic" />
                </div>
                <div className="memberName">
                  <div className="profileBox">
                    <div className="memberTitleClickable" onClick={() => this.setState({ tab: 3 })}>
                      Apply Now!
                    </div>
                    <div className="memberRealname">
                      Open Position
                  </div>
                  </div>
                </div>
                <div className="memberNameParagraph">
                Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
                </div>
            </div>
            <div className="teamMember">
                <div className="pictureWidth">
                  <img src={noMember} alt="member1" className="memberPic" />
                </div>
                <div className="memberName">
                  <div className="profileBox">
                    <div className="memberTitleClickable" onClick={() => this.setState({ tab: 3 })}>
                      Apply Now!
                    </div>
                    <div className="memberRealname">
                      Open Position
                  </div>
                  </div>
                </div>
                <div className="memberNameParagraph">
                Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
                </div>
            </div>
            <div className="teamMember">
                <div className="pictureWidth">
                  <img src={noMember} alt="member1" className="memberPic" />
                </div>
                <div className="memberName">
                  <div className="profileBox">
                    <div className="memberTitleClickable" onClick={() => this.setState({ tab: 3 })}>
                      Apply Now!
                    </div>
                    <div className="memberRealname">
                      Open Position
                  </div>
                  </div>
                </div>
                <div className="memberNameParagraph">
                Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
                </div>
            </div>
        </div>
      );
  }

  teamPage() {
    const { team } = this.state;
    return (
      <div>
        <br />
        <Row>
          <div className={`buttonParallel parallelogram ${team === 1 ? 'buttonParallelActive' : ''}`} onClick={() => this.setState({ team: 1 })}>
            <span className="skew-fix">
              Main Roster
            </span>
          </div>
        </Row>
        <br />
        <Row>
          {team === 1 &&
            <div className="teamContainer">
              {this.team1()}
            </div>
          }
        </Row>
      </div>
    );
  }

  render() {
    const { tab } = this.state;

    let content;
    switch (tab) {
      case 1:
        content = homePage;
        break;
      case 2:
        content = this.teamPage();
        break;
      case 3:
        content = recruitPage;
        break;
      default:
        break;
    }

    return (
      <div className="canScroll">
        <div className="whitishBG">
          <div className="sidenav">
            <div className="borderNav">
              <p className={tab === 1 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 1 })}>Home</p>
              <p className={tab === 2 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 2 })}>Collegiate Team</p>
              <p className={tab === 3 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 3 })}>Recruitment</p>
            </div>
          </div>
          <div className="left">
            <Container>
              {content}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Overwatch;