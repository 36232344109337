import React from 'react';
import { Calendar, Badge } from 'antd';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const API_KEY = 'AIzaSyAYwGOhaeDIkBsq_5EVvxh-tR05YMQjuCA';
// const CALENDAR_ID = 'vt.edu_1fklib4p77qs5ucnoa1ce7o1uk@group.calendar.google.com';
const CALENDAR_ID = 'vespaesportsvt%40gmail.com';
const url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`

const colors = [
    'pink',
    'red',
    'yellow',
    'orange',
    'cyan',
    'green',
    'blue',
    'purple',
    'geekblue',
    'magenta',
    'volcano',
    'gold',
    'lime',
];

const removeMili = (time) => {
    const split = time.split(':');
    const ending = time.slice(-2);
    const string = `${split[0]}:${split[1]} ${ending}`;

    return string;
}

const getContent = (events) => {
    if (events.length === 0) {
        return <div className="biggerFont">No events on this day</div>
    }

    const content = (
        <div>
            {events.map((event) => {
                return (
                    <div key={event.updated} className="biggerFont badgeFont">
                        <Badge color={event.color} />
                        {event.summary}
                        <ul>
                            {event.startDate && <li className="grey"><div className="listStart">Start</div>&nbsp;-&nbsp;<div>{`${removeMili(event.startDate.toLocaleTimeString('en-US'))} ${event.startDate.toDateString()}`}</div></li>}
                            {event.endDate && <li className="grey"><div className="listStart">End</div>&nbsp;-&nbsp;<div>{`${removeMili(event.endDate.toLocaleTimeString('en-US'))} ${event.endDate.toDateString()}`}</div></li>}
                            {event.location && <li className="grey"><div className="listStart">Where</div>&nbsp;-&nbsp;<div>{`${event.location}`}</div></li>}
                            {event.description && <li className="grey"><div className="listStart">Description</div>&nbsp;-&nbsp;<div>{`${event.description}`}</div></li>}
                        </ul>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div>
            {content}
        </div>
    );
};

const getDate = (date) => {
    if (date.dateTime) {
        const dateSplit = date.dateTime.split('T');

        if (dateSplit.length === 2) {
            const frontHalf = dateSplit[0];
            const frontSplit = frontHalf.split('-');

            let year;
            let month;
            let day;

            if (frontSplit.length === 3) {
                year = parseInt(frontSplit[0], 10);
                month = parseInt(frontSplit[1], 10) - 1;
                day = parseInt(frontSplit[2], 10);
            } else {
                return new Date();
            }

            const backHalf = dateSplit[1];
            const backSplit = backHalf.split(':');

            let hour;
            let minute;

            if (backSplit.length >= 4) {
                hour = parseInt(backSplit[0], 10);
                minute = parseInt(backSplit[1], 10);
            } else {
                return new Date();
            }

            return new Date(year, month, day, hour, minute);
        }

        return new Date();
    }
    if (date.date) {
        const dateSplit = date.date.split('-');

        if (dateSplit.length === 3) {
            const year = parseInt(dateSplit[0], 10);
            const month = parseInt(dateSplit[1], 10) - 1;
            const day = parseInt(dateSplit[2], 10);

            return new Date(year, month, day);
        }
        return new Date();
    }
    console.warn('Date has a bad attribute');
    return new Date();
};

const parseEvents = (items) => {
    let returnedList = [];

    items.forEach((item, currentIndex) => {
        const headers = { color: colors[currentIndex % colors.length] };
        if (item.originalStartTime) {
            headers.oneDate = true;
            headers.startDate = getDate(item.originalStartTime);
        }
        else if (!item.end) {
            headers.oneDate = true;
            headers.startDate = getDate(item.start);
        } else {
            headers.oneDate = false;
            headers.startDate = getDate(item.start);
            headers.endDate = getDate(item.end);
        }
        const copy = Object.assign(item, headers);


        // Cancel case *There may be a case this fails if the recurrence event appears after this event* (need to look into that issue potentially)
        if (copy.status === 'cancelled') {
            const split = copy.id.split('_');

            if (split.length === 2) {
                const id = split[0];
                const backHalf = split[1].split('T');

                if (backHalf.length === 2) {
                    const fullDate = backHalf[0];

                    const year = parseInt(fullDate.substring(0, 4), 10);
                    const month = parseInt(fullDate.substring(4, 6), 10) - 1;
                    const day = parseInt(fullDate.substring(6, 8), 10);

                    const removeDate = new Date(year, month, day);

                    returnedList = returnedList.filter((event) => {
                        if (event.id === id && event.startDate.getFullYear() === removeDate.getFullYear() && event.startDate.getMonth() === removeDate.getMonth()
                            && event.startDate.getDate() === removeDate.getDate()) {
                            return false;
                        }
                        return true;
                    });
                }
            }
        }

        // Recurrence case
        if (copy.recurrence) {
            const rule = copy.recurrence[0];
            const temp = rule.split('RRULE:');

            if (temp.length === 2) {
                const line = temp[1];

                const ruleSplit = line.split(';');
                let dayMultiplyer = 1;
                let until = copy.startDate;
                let interval = 1;
                // let byDay; /* I think this field is irrelevant ? */

                ruleSplit.forEach((rule) => {
                    const arr = rule.split('=');
                    if (arr.length === 2) {
                        const command = arr[0];
                        const value = arr[1];

                        if (command === 'FREQ') {
                            /* Add more if cases as necessary */
                            if (value === 'WEEKLY') {
                                dayMultiplyer = 7;
                            } else {
                                dayMultiplyer = 1;
                            }
                        }

                        if (command === 'UNTIL') {
                            const valueSplit = value.split('T');
                            if (valueSplit.length === 2) {
                                const fullDate = valueSplit[0];

                                const year = parseInt(fullDate.substring(0, 4), 10);
                                const month = parseInt(fullDate.substring(4, 6), 10) - 1;
                                const day = parseInt(fullDate.substring(6, 8), 10);

                                until = new Date(year, month, day);
                            }
                        }

                        if (command === 'INTERVAL') {
                            interval = parseInt(value, 10);
                        }

                        /* if (command === 'BYDAY') {
                            byDay = value;
                        } */
                    }
                });

                let newStartDate = new Date(copy.startDate.getTime());
                let newEndDate = copy.endDate ? new Date(copy.endDate.getTime()) : null;
                let recurCopy = Object.assign({}, copy);

                newStartDate.setDate(newStartDate.getDate() + (dayMultiplyer * interval));
                if (newEndDate) newEndDate.setDate(newEndDate.getDate() + (dayMultiplyer * interval));

                while (newStartDate < until) {
                    let newHeaders = {};
                    newHeaders.startDate = newStartDate;
                    if (newEndDate) newHeaders.endDate = newEndDate;

                    const tempObj = Object.assign(recurCopy, newHeaders);
                    returnedList.push(tempObj);

                    // Might cause memory leaks here
                    recurCopy = Object.assign({}, tempObj);
                    newStartDate = new Date(newStartDate.getTime());
                    newStartDate.setDate(newStartDate.getDate() + (dayMultiplyer * interval));
                    if (newEndDate) {
                        newEndDate = new Date(newEndDate.getTime());
                        newEndDate.setDate(newEndDate.getDate() + (dayMultiplyer * interval));
                    }
                }
            }
        }
        returnedList.push(copy);
    });
    return returnedList;
};

const getEvents = (date, items) => {
    const listData = [];

    items.forEach((event) => {
        if (event.status === 'cancelled') return;

        if (event.oneDate) {
            if (event.startDate.getFullYear() === date.getFullYear() && event.startDate.getMonth() === date.getMonth() && event.startDate.getDate() === date.getDate()) {
                listData.push(event);
            }
        } else {
            const startBound = new Date(event.startDate.getFullYear(), event.startDate.getMonth(), event.startDate.getDate());
            const endBound = new Date(event.endDate.getFullYear(), event.endDate.getMonth(), event.endDate.getDate());

            // same day but different hours
            if (startBound.getTime() === date.getTime()) {
                if (startBound.getTime() === date.getTime()) {
                    listData.push(event);
                }
                return;
            }

            if (startBound <= date && date < endBound) {
                listData.push(event);
            }
        }
    });

    return listData;
};

class Events extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            selectedDate: new Date(),
            showModal: false,
            items: [],
        };
        this.dateCellRender = this.dateCellRender.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ showModal: false });
    }

    componentDidMount() {
        fetch(url, { method: 'GET' })
            .then((res) => res.json().then((res) => {
                const parsed = parseEvents(res.items);
                this.setState({ items: parsed });
            })
                , () => {
                    console.warn('error');
                });
    }

    getListData(value, eventList) {
        let listData = [];
        const currDate = new Date(value.year(), value.month(), value.date());

        eventList.forEach((event) => {
            if (event.status === 'cancelled') return;

            if (event.oneDate) {
                if (event.startDate.getFullYear() === currDate.getFullYear() && event.startDate.getMonth() === currDate.getMonth() && event.startDate.getDate() === currDate.getDate()) {
                    listData.push({ type: 'warning', content: event.summary, color: event.color });
                }
            } else {
                const startBound = new Date(event.startDate.getFullYear(), event.startDate.getMonth(), event.startDate.getDate());
                const endBound = new Date(event.endDate.getFullYear(), event.endDate.getMonth(), event.endDate.getDate());

                // same day but different hours
                if (startBound.getTime() === endBound.getTime()) {
                    if (startBound.getTime() === currDate.getTime()) {
                        listData.push({ type: 'warning', content: event.summary, color: event.color });
                    }
                    return;
                }

                if (startBound <= currDate && currDate < endBound) {
                    listData.push({ type: 'success', content: event.summary, color: event.color });
                }
            }
        });

        return listData;
    }

    dateCellRender(date) {
        const { items } = this.state;
        const listData = this.getListData(date, items);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge color={item.color} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    }

    onSelect(date) {
        const { items } = this.state;

        const year = date.year();
        const month = date.month();
        const day = date.date();

        const selectedDate = new Date(year, month, day);
        const selected = getEvents(selectedDate, items);

        this.setState({ selectedDate, selected, showModal: true });
    }

    render() {
        const { showModal, selectedDate, selected } = this.state;

        return (
            <div className="canScroll">
                <div className="aboutBody padding15">
                    <center>
                        <div className="section-title">
                            Events
                        </div>
                        <Calendar dateCellRender={this.dateCellRender} onSelect={this.onSelect} />

                        <Modal size="lg" show={showModal} onHide={() => this.handleClose()} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div className="modalHeader">
                                        {selectedDate.toDateString()}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modalBody">
                                    {getContent(selected)}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn-maroon" onClick={() => this.handleClose()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </center>
                </div>
            </div>
        );
    }
}

export default Events;