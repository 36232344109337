import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import noMember from '../img/NoMember.png';

class Smite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1, team: 1,
    }
    this.teamPage = this.teamPage.bind(this);
  }

  team1() {
    return (
        <div>
          <div className="fontPicker purpleText">
            This roster will compete in the National Association of Collegiate Esports Smite Tournament.
          </div>
          <br />
          <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    HAIL THE BEAN
                    </div>
                <div className="memberRealname">
                    Matthew Dean
                </div>
          <div className="memberRole">
            Role:&nbsp;Solo Lane
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Freshman going into Mechanical Engineering.
            </div>
        </div>
        <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    Mister_Ham
                    </div>
                <div className="memberRealname">
                    Elliot Rheault
                </div>
          <div className="memberRole">
            Role:&nbsp;Jungle
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Sophomore double majoring in Computer Science and Computer Engineering.
            <br />
            <br />
            Favorite Champ: Thoth
            </div>
        </div>
        <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    KrakenKO
                    </div>
                <div className="memberRealname">
                    Alex Ford
                </div>
          <div className="memberRole">
            Role:&nbsp;Mid
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Junior majoring in Electrical Engineering. Captain for the smite team.
            <br />
            <br />
            Favorite Champ: Nox
            </div>
        </div>
        <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    Luna4513
                    </div>
                <div className="memberRealname">
                    Hannah Brown
                </div>
          <div className="memberRole">
            Role:&nbsp;ADC
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Junior majoring in History.
            <br />
            <br />
            Favorite Champ: Neith
            </div>
        </div>
        <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    HokieFan49
                    </div>
                <div className="memberRealname">
                  Andrew Eberly
                </div>
          <div className="memberRole">
            Role:&nbsp;Support
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Sophomore majoring in Mathematics.
            <br />
            <br />
            Favorite Champ: Vamana (specifically with the Lil’ Mana skin)
            </div>
        </div>
        <div className="teamMember">
            <div className="pictureWidth">
              <img src={noMember} alt="member1" className="memberPic" />
            </div>
            <div className="memberName">
                <div className="profileBox">
                    <div className="memberTitle">
                    ClarkyBarky
                    </div>
                <div className="memberRealname">
                  Reilly Clark
                </div>
          <div className="memberRole">
            Role:&nbsp;Substitute
            </div>
                </div>
            </div>
            <div className="memberNameParagraph">
            Sophomore CMDA major who enjoys writing code, watching esports and playing video games.
            <br />
            <br />
            Favorite Champ: Anubis
            </div>
        </div>
      </div>
      );
  }

  teamPage() {
    const { team } = this.state;
    return (
      <div>
        <br />
        <Row>
          <div className={`buttonParallel parallelogram ${team === 1 ? 'buttonParallelActive' : ''}`} onClick={() => this.setState({ team: 1 })}>
            <span className="skew-fix">
              Main Roster
            </span>
          </div>
        </Row>
        <br />
        <Row>
          {team === 1 &&
            <div className="teamContainer">
              {this.team1()}
            </div>
          }
        </Row>
      </div>
    );
  }

  render() {
    const { tab } = this.state;

    let content;
    switch (tab) {
      case 1:
        content = this.teamPage();
        break;
      default:
        break;
    }

    return (
      <div className="canScroll">
        <div className="whitishBG">
          <div className="sidenav">
            <div className="borderNav">
              <p className={tab === 1 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 1 })}>Collegiate Team</p>
            </div>
          </div>
          <div className="left">
            <Container>
              {content}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Smite;