import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const VentureGames = () => (
    <div className="canScroll">
        <div className="aboutBody">
            <Container className="padding15">
                <Row>
                    <Col>
                        <div className="text-center">
                            <div className="section-title">
                                Venture Games
                            </div>
                            <div className="fontBigger">
                                We are currently growing communties for the following games:
                            </div>
                            <br />
                            <center>
                                <Link to="/Smite" className="available ventureFont"> Smite </Link>
                                <div className="availableNot ventureFont">
                                    Rainbow Six Siege
                                </div>
                                <div className="availableNot ventureFont">
                                    Fortnite
                                </div>
                                <div className="availableNot ventureFont">
                                    Hearthstone
                                </div>
                                <div className="availableNot ventureFont">
                                    Rocket League
                                </div>
                                <div className="availableNot ventureFont">
                                    PUBG
                                </div>
                            </center>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
);

export default VentureGames;
