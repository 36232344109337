import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

class Stream extends React.Component {
    componentDidMount() {
        // eslint-disable-next-line no-unused-vars
        let embed;
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            EMBED_URL
        );
        script.addEventListener('load', () => {
            embed = new window.Twitch.Embed(this.props.targetID, { ...this.props });
        });
        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="canScroll">
                <div className="aboutBody">
                    <Container className="padding15">
                        <Row>
                            <Col>
                                <center>
                                    <div className="section-title">
                                        Stream
                                    </div>
                                    <div id={this.props.targetID} />
                                    <br />
                                    <iframe title="chat" width="100%" height="500px" frameBorder="0" scrolling="no" src="http://twitch.tv/chat/embed?channel=vespalive&amp;popout_chat=true"></iframe>
                                </center>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

Stream.defaultProps = {
    targetID: 'twitch-embed',
    width: '100%',
    height: '640',
    layout: 'video',
    channel: 'VESPALive',
}


export default Stream;