import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import homeImg from '../img/LeagueHome.JPG';
import noMember from '../img/NoMember.png';
import topLogo from '../img/lol-topOrange.png';
import jgLogo from '../img/lol-junglerOrange.png';
import midLogo from '../img/lol-midOrange.png';
import botLogo from '../img/lol-bottomOrange.png';
import REIlogo from '../img/REI_Logo_Large.jpg';

const homePage = (
  <div className="text-center">
    <div className="section-title">
      Welcome to the League of Legends Page
    </div>
    <div className="fontBigger">
      Being one of the most popular games, the League of Legends community is the largest of all in VESPA.
      <br />
      <br />
      We meet every other week in Torgersen Hall. Come join us and play some custom games with other club members, socialize, and most importantly, have fun!
    </div>
    <br />
    <img src={homeImg} alt="homeImg" className="width90" />
  </div>
);

const ggPage = (
  <div className="text-center">
    <div className="section-title">
      Gobbler Gauntlet
    </div>
    <div className="fontBigger">
      The Gobbler Gauntlet is an intraclub League of Legends tournament that occurs every semester.
      Club members who register may choose to be captains or enter the draft. Captains then draft players
      from those in the draft onto teams to compete in a semester-long tournament for cash prizing, loot, riot prizing and most importantly:
      club-bragging rights. The GG is our most popular event and is gobbles of fun.
    </div>
    <br />
    <br />
    <div className="section-title">
      How to Join
    </div>
    <div className="fontBigger">
      More information will be available soon
    </div>
  </div>
);

const sponsorPage = (
  <div className="text-center section-title">
    <div>
    The League of Legends Team is proud to say we are sponsored by REI Systems:
    </div>
    <a href="https://www.reisystems.com/" target="_blank" rel="noopener noreferrer">
      <img src={REIlogo} className="width50" alt="REI Systems: Helping VT Esports Succeed!" />
    </a>
    <br />
    <br />
    <br />
    <div className="businessInquiry">
      For business inquiries please email: gavinm97@vt.edu
    </div>
  </div>
);

const recruitPage = (
  <div>
    <Row>
      <Col>
        <div className="text-center section-titleSmaller">
          LoL Player Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the collegiate team, there are responsibilites we will hold you accountable for which includes:
          </div>
          <br />
          <div>
            - Being on time to games and scrims. Do not show up the minute right before.
          </div>
          <br />
          <div>
            - Being able to attend in-person vod reviews (not all but at least some).
          </div>
          <br />
          <div>
            - Not being toxic. Not only do you need to be a good teammmate, you will be representing the club.
          </div>
          <br />
          <div>
            - Being able to communicate in and out of game. Effective communication is what will make the team better
          </div>
          <br />
          <div>
            - Having a growth mindset. An improvement mentality is a must on the team.
          </div>
          <br />
          <a className="btn maroon-btn" href="https://discord.gg/P2TKzu3" target="_blank" rel="noopener noreferrer">Click Here To Apply!</a>
        </div>
      </Col>
      <Col>
        <div className="text-center section-titleSmaller">
          LoL Staff Position
        </div>
        <div className="text-center recruitFont">
          <div>
            If you are interested in becoming a part of the staffing team, there are multiple positions available which include:
          </div>
          <br />
          <br />
          <div>
            <b>Coach</b> - Responsible for the overall direction of the team. Must be knowledgable in game and have leadership and communication attributes.
          </div>
          <br />
          <div>
            <b>Analyst</b> - Responsible for keeping track of statistics and maintaining spreadsheets. Preferrably have experience with Excel.
          </div>
          <br />
          <div>
            <b>Psychologist</b> - Responsible for improving the mental endurance and strength of players. Must have good people skills.
          </div>
          <br />
          <br />
          <br />
          <div className="interested">
            <div>
              If interested, contact Gavin Merkel:
            </div>
            <div>
              Email - gavinm97@vt.edu
            </div>
            <div>
              Discord - gavinm97#3278
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

const team1 = (
  <div>
    <div className="fontPicker purpleText">
    The main roster comprises of the best players the club has to offer. Giving no less than 110%, players and staff members of this roster are
    expected to put in every ounce of effort into the team. This roster is for those with an extreme competitive drive and is not for the faint of heart.
    If you manage to earn a spot on the team, you will be expected to participate in Riot Games's Collegiate League of Legends Tournament and represent Virginia Tech.
    </div>
    <br />
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Yade or Yade
        </div>
          <div className="memberRealname">
            Chase Martin
        </div>
          <div className="memberRole">
            Role:&nbsp;&nbsp;&nbsp;&nbsp;
          <img src={topLogo} alt="top" />
            &nbsp;Top
        </div>
        </div>
      </div>
      <div className="memberNameParagraph">
        Being one of the longest tenured member on the Virginia Tech League of Legends team, Chase 'yade or yade' Martin is one of
        the cornerstones of the team's success since he joined as a freshman. He is currently a junior majoring in Spanish and hopes
        to bring home a couple more gold medal for Virginia Tech before graudating.
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Flowerkitty
      </div>
          <div className="memberRealname">
            Aaron Waters
      </div>
          <div>
            Role:&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={jgLogo} alt="jungler" />
            &nbsp;Jungler
      </div>
        </div>
      </div>
      <div className="memberNameParagraph">
        Transferred from Tidewater Community College, Aaron 'flowerkitty' Waters joined the Virginia Tech League of Legends team as the starting jungler in 2018.
        His strategic calls and vocal communication in game has made him a valuable addition to the team.
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Aceˉ
          </div>
          <div className="memberRealname">
            Justin Tucker
          </div>
          <div>
            Role:&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={midLogo} alt="mid" />
            &nbsp;Mid
      </div>
        </div>
      </div>
      <div className="memberNameParagraph">
        From Fairfax, Virginia, Justin 'ace' Tucker is the newest member of the team. He held a high rank on the ladder back in season 7 and then
        took a break from League to play varsity football for his highschool team. Now coming to Virginia Tech, Justin is looking to re-ignite the old flame and 
        carry over his same level of dominance.
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Phongfrancis
          </div>
          <div className="memberRealname">
            Francis Nguyen
          </div>
          <div>
            Role:&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={botLogo} alt="bot" />
            &nbsp;AD Carry
      </div>
        </div>
      </div>
      <div className="memberNameParagraph">
        As the team captain, Francis 'phongfrancis' Nguyen is responsible for overseeing the Virginia Tech League of Legends team. He has had a passion for E-Sports ever since 
        he was a kid and is leading the movement to get Virginia Tech to legitimize League as a sport. His ultimate goal is to have the school recognize players on 
        the team as athletes before he graduates.
      </div>
    </div>
    <div className="teamMember">
      <div className="pictureWidth">
        <img src={noMember} alt="member1" className="memberPic" />
      </div>
      <div className="memberName">
        <div className="profileBox">
          <div className="memberTitle">
            Smite Infinity
          </div>
          <div className="memberRealname">
            Daniel Cho
          </div>
          <div>
            Role:&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={botLogo} alt="bot" />
            &nbsp;Support
      </div>
        </div>
      </div>
      <div className="memberNameParagraph">
        Born and raised in Centreville, Virginia, Daniel 'Smite Infinity' Cho comes in as one of the more veteran players of the team. He has played the top, mid, and support 
        role before in previous iterations of the Virginia Tech team and will be moving foward as the support this year. He hopes to place 1st in uLoL and fly out to LA
        and represent Virginia Tech.
      </div>
    </div>
  </div>
);



class League extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1, team: 1,
    }
    this.teamPage = this.teamPage.bind(this);
  }

  team2() {
    return (
      <div>
        <div className="fontPicker purpleText">
        Bred for success, players of the academy team are cultivated from our very own community at Virginia Tech and aim to compete.
        Employed to serve as a pool of talented players who seek to improve from good to great. Their success as individuals marks them as true talent and given the
        chance to play as a team they will learn invaluable skills moving forward. Opportunities to play with and against the main roster are numerable, 
        as is the ability to compete as an entirely separate entity in their own competitive environment.
        </div>
        <br />
        <div className="teamMember">
          <div className="pictureWidth">
            <img src={noMember} alt="member1" className="memberPic" />
          </div>
          <div className="memberName">
            <div className="profileBox">
              <div className="memberTitleClickable" onClick={() => this.setState({ tab: 4 })}>
                Apply Now!
              </div>
              <div className="memberRealname">
                Open Position
            </div>
              <div className="memberRole">
                Role:&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={topLogo} alt="top" />
                &nbsp;Top
            </div>
            </div>
          </div>
          <div className="memberNameParagraph">
            Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
          </div>
        </div>
        <div className="teamMember">
          <div className="pictureWidth">
            <img src={noMember} alt="member1" className="memberPic" />
          </div>
          <div className="memberName">
            <div className="profileBox">
              <div className="memberTitleClickable" onClick={() => this.setState({ tab: 4 })}>
                Apply Now!
              </div>
              <div className="memberRealname">
                Open Position
            </div>
              <div className="memberRole">
                Role:&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={jgLogo} alt="top" />
                &nbsp;Jungler
            </div>
            </div>
          </div>
          <div className="memberNameParagraph">
            Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
          </div>
        </div>
        <div className="teamMember">
          <div className="pictureWidth">
            <img src={noMember} alt="member1" className="memberPic" />
          </div>
          <div className="memberName">
            <div className="profileBox">
              <div className="memberTitleClickable" onClick={() => this.setState({ tab: 4 })}>
                Apply Now!
              </div>
              <div className="memberRealname">
                Open Position
            </div>
              <div className="memberRole">
                Role:&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={midLogo} alt="top" />
                &nbsp;Mid
            </div>
            </div>
          </div>
          <div className="memberNameParagraph">
            Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
          </div>
        </div>
        <div className="teamMember">
          <div className="pictureWidth">
            <img src={noMember} alt="member1" className="memberPic" />
          </div>
          <div className="memberName">
            <div className="profileBox">
              <div className="memberTitleClickable" onClick={() => this.setState({ tab: 4 })}>
                Apply Now!
              </div>
              <div className="memberRealname">
                Open Position
            </div>
              <div className="memberRole">
                Role:&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={botLogo} alt="top" />
                &nbsp;AD Carry
            </div>
            </div>
          </div>
          <div className="memberNameParagraph">
            Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
          </div>
        </div>
        <div className="teamMember">
          <div className="pictureWidth">
            <img src={noMember} alt="member1" className="memberPic" />
          </div>
          <div className="memberName">
            <div className="profileBox">
              <div className="memberTitleClickable" onClick={() => this.setState({ tab: 4 })}>
                Apply Now!
              </div>
              <div className="memberRealname">
                Open Position
            </div>
              <div className="memberRole">
                Role:&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={botLogo} alt="top" />
                &nbsp;Support
            </div>
            </div>
          </div>
          <div className="memberNameParagraph">
            Think what it has to compete on a collegiate level? Click on the 'Apply Now!' text.
          </div>
        </div>
      </div>
    );
  }


  teamPage() {
    const { team } = this.state;
    return (
      <div>
        <br />
        <Row>
          <div className={`buttonParallel parallelogram ${team === 1 ? 'buttonParallelActive' : ''}`} onClick={() => this.setState({ team: 1 })}>
            <span className="skew-fix">
              Main Roster
            </span>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className={`buttonParallel parallelogram ${team === 2 ? 'buttonParallelActive' : ''}`} onClick={() => this.setState({ team: 2 })}>
            <span className="skew-fix">
              Academy
            </span>
          </div>
        </Row>
        <br />
        <Row>
          {team === 1 &&
            <div className="teamContainer">
              {team1}
            </div>
          }
          {team === 2 &&
            <div className="teamContainer">
              {this.team2()}
            </div>
          }
        </Row>
      </div>
    );
  }

  render() {
    const { tab } = this.state;

    let content;
    switch (tab) {
      case 1:
        content = homePage;
        break;
      case 2:
        content = ggPage;
        break;
      case 3:
        content = this.teamPage();
        break;
      case 4:
        content = recruitPage;
        break;
      case 5:
        content = sponsorPage;
        break;
      default:
        break;
    }

    return (
      <div className="canScroll">
        <div className="whitishBG">
          <div className="sidenav">
            <div className="borderNav">
              <p className={tab === 1 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 1 })}>Home</p>
              <p className={tab === 2 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 2 })}>Gobbler Gauntlet</p>
              <p className={tab === 3 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 3 })}>Collegiate Team</p>
              <p className={tab === 4 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 4 })}>Recruitment</p>
              <p className={tab === 5 ? 'sideNavActive' : ''} onClick={() => this.setState({ tab: 5 })}>Sponsors</p>
            </div>
          </div>
          <div className="left">
            <Container>
              {content}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default League;