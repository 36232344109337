import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { Spin, Icon } from 'antd';
import logo from '../img/VespaLogo.png';
import '../App.css';

// webiste for helping;
// https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server

const currentwebappURL = 'https://script.google.com/macros/s/AKfycbwrr0RC-lP9JVHsQlB4_F6UfPc38D2ZVL2Q6MOmAs1GkHpeWJ_e/exec';

const spreadsheetID = '1t_KlVtJ4eclLoLLlNiVczTELPzJeypdJo5Wr4MXdqS0';
export const API_KEY = 'AIzaSyAYwGOhaeDIkBsq_5EVvxh-tR05YMQjuCA';
const getSpreadsheetURL = (range) => `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetID}/values/${range}?key=${API_KEY}`;

const spinningIcon = <Icon type="loading" style={{ fontSize: 36 }} spin />;

const hashCode = (string) => {
  let hash = 0;
  if (string.length === 0) {
    return hash;
  }

  for (let x = 0; x < string.length; x += 1) {
    let char = string.charCodeAt(x);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
};

class NavHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loginTab: 0,
      signedIn: false,
      signInUsername: '',
      signInPassword: '',
      invalidCredentials: false,
      loadingSignIn: false,
      memberIndex: null,
      gobblerConnect: false,
      paidDues: false,

      registerUsername: '',
      registerEmail: '',
      registerPassword: '',
      registerPassword2: '',
      invalidRegister: false,
      invalidRegisterReasons: [],
      loadingRegister: false,
      successfulRegister: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  handleSignIn() {
    const { signInUsername, signInPassword } = this.state;

    this.setState({ loadingSignIn: true });

    fetch(getSpreadsheetURL('A2:E'), {
      method: 'GET',
    })
      .then((res) => res.json().then((res) => {
        let index = -1;
        const arr = res.values;
        const encryptPass = hashCode(signInPassword).toString();

        arr.forEach((arrayEntry, currentIndex) => {
          if (arrayEntry.length === 5 && arrayEntry[0] === signInUsername && arrayEntry[1] === encryptPass) {
            index = currentIndex;
            if (arrayEntry[3] && arrayEntry[3] === 'yes') this.setState({ gobblerConnect: true });
            else this.setState({ gobblerConnect: false });

            if (arrayEntry[4] && arrayEntry[4] === 'yes') this.setState({ paidDues: true });
            else this.setState({ paidDues: false });

            return;
          }
        });

        if (index === -1) this.setState({ invalidCredentials: true });
        else this.setState({ memberIndex: index, signedIn: true });

        this.setState({ loadingSignIn: false });
      })
        , () => {
          console.warn('error');
        });
  }

  signInBody() {
    const { signInUsername, signInPassword, invalidCredentials, loadingSignIn } = this.state;

    const signInBody = (
      <div>
        <div className="fontPicker ml-4">
          <div>
            <i className="fas fa-user mr-1" />
            Username
            &nbsp;
          <input className="loginInput" type="text" value={signInUsername} onChange={(e) => this.setState({ signInUsername: e.target.value, invalidCredentials: false })} />
          </div>
          <br />
          <div>
            <i className="fas fa-lock prefix mr-1" />
            Password
            &nbsp;
          <input className="loginInput ml-1" type="password" value={signInPassword} onChange={(e) => this.setState({ signInPassword: e.target.value, invalidCredentials: false })} />
          </div>
          <br />
          {invalidCredentials && (
            <div className="redText">
              Invalid Credentials
          </div>
          )}
          <br />
          <center>
            <Button disabled={signInUsername === '' || signInPassword === ''} className="btn-maroon loginButton" onClick={() => this.handleSignIn()}>Log in</Button>
          </center>
          <br />
        </div>
        <center className="fontPicker forgetPassword">
          Forgot password?
          Email francisp@vt.edu or jmunzing@vt.edu
      </center>
      </div>
    );

    return loadingSignIn ? (
      <div>
        <Spin indicator={spinningIcon} >
          {signInBody}
        </Spin>
      </div>
    ) : signInBody;
  }

  handleRegister() {
    const { registerUsername, registerEmail, registerPassword, registerPassword2 } = this.state;
    const reasons = [];
    const regex = /\W/;

    if (registerUsername.indexOf(' ') >= 0) reasons.push('Username cannot contain spaces');
    else if (regex.test(registerUsername)) reasons.push('Username can only contain letters, numbers, and underscores');

    if (registerEmail.indexOf('@') >= 0) {
      const emailSplit = registerEmail.split('@');
      if (emailSplit.length === 2) {
        if (emailSplit[1].length <= 0) {
          reasons.push('Email is not valid');
        } else if (emailSplit[1] !== 'vt.edu') {
          reasons.push('Not a @vt.edu email');
        }
      } else {
        reasons.push('Email is not valid');
      }
    } else {
      reasons.push('Email does not contain @');
    }

    if (registerPassword !== registerPassword2) {
      reasons.push('Passwords are not the same');
    } else {
      if (registerPassword.indexOf(' ') >= 0) reasons.push('Password cannot contain spaces');
      else if (regex.test(registerPassword)) reasons.push('Password can only contain letters, numbers, and underscores');
    }

    if (reasons.length !== 0) {
      this.setState({ invalidRegisterReasons: reasons, invalidRegister: true });
    } else {
      this.setState({ loadingRegister: true })

      fetch(getSpreadsheetURL('A2:C'), {
        method: 'GET',
      })
        .then((res) => res.json().then((res) => {
          const arr = res.values;

          arr.forEach((arrayEntry, currentIndex) => {
            if (arrayEntry.length === 3) {
              if (arrayEntry[0] === registerUsername) reasons.push('Username already taken');
              if (arrayEntry[2] === registerEmail) reasons.push('Email already taken');
              return;
            }
          });

          if (reasons.length > 0) {
            this.setState({ invalidRegisterReasons: reasons, invalidRegister: true, loadingRegister: false });
          } else { // Success Path
            this.setState({ invalidRegisterReasons: [], invalidRegister: false });

            const formData = new FormData();
            formData.append('Username', registerUsername);
            formData.append('Password', hashCode(registerPassword));
            formData.append('Email', registerEmail);
            formData.append('GobblerConnect', 'no');
            formData.append('PaidDues', 'no');

            fetch(currentwebappURL, {
              method: 'POST',
              body: formData,
            })
              .then((res) => res.json().then((res) => {
                this.setState({ loadingRegister: false, registerUsername: '', registerEmail: '', registerPassword: '', registerPassword2: '', successfulRegister: true });
              })
                , () => {
                  console.warn('error');
                });
          }
        })
          , () => {
            console.warn('error');
          });
    }
  }

  registerBody() {
    const { registerUsername, registerEmail, registerPassword, registerPassword2, invalidRegister, loadingRegister, invalidRegisterReasons, successfulRegister } = this.state;

    const registerBody = (
      <div>
        <div className="fontPicker ml-4">
          <div>
            <i className="fas fa-user mr-1" />
            Username
            &nbsp;
          <input className="loginInput ml-2" type="text" value={registerUsername} onChange={(e) => this.setState({ registerUsername: e.target.value, invalidRegister: false })} />
          </div>
          <br />
          <div>
            <i className="fas fa-envelope prefix mr-1" />
            Email
            &nbsp;
          <input className="loginInput ml-5" type="text" value={registerEmail} onChange={(e) => this.setState({ registerEmail: e.target.value, invalidRegister: false })} />
          </div>
          <br />
          <div>
            <i className="fas fa-lock prefix mr-1" />
            Password
            &nbsp;
          <input className="loginInput ml-3" type="password" value={registerPassword} onChange={(e) => this.setState({ registerPassword: e.target.value, invalidRegister: false })} />
          </div>
          <br />
          <div>
            <i className="fas fa-lock prefix mr-1" />
            Repeat Password
            &nbsp;
          <input className="loginInput ml-1" type="password" value={registerPassword2} onChange={(e) => this.setState({ registerPassword2: e.target.value, invalidRegister: false })} />
          </div>
          <br />
          {invalidRegister && (
            <div className="redText">
              <center>Invalid:</center>
              {invalidRegisterReasons.map((reason) => <div>{`- ${reason}`}</div>)}
            </div>
          )}
          <br />
          <center>
            <Button disabled={registerUsername === '' || registerEmail === '' || registerPassword === '' || registerPassword2 === ''} className="btn-maroon loginButton" onClick={() => this.handleRegister()}>Sign Up</Button>
          </center>
          <br />
        </div>
      </div>
    );

    const successfulBody = (
      <div className="fontPicker">
        <center>
          <b>Successfully created an account!</b>
        </center>
        <br />
        <center>
          <div className="registerClickable" onClick={() => this.setState({ loginTab: 0, successfulRegister: false })}>
            Click here to go to the login page.
          </div>
        </center>
      </div>
    );

    if (loadingRegister) {
      return (
        <div>
          <Spin indicator={spinningIcon} >
            {registerBody}
          </Spin>
        </div>
      );
    } else {
      if (successfulRegister) {
        return successfulBody;
      }
      return registerBody;
    }
  }

  getLogInBody() {
    const { loginTab } = this.state;
    return loginTab === 0 ? this.signInBody() : this.registerBody();
  }

  handleClose() {
    this.setState({ show: false, successfulRegister: false, });
  }

  handleSignOut() {
    this.setState({
      signedIn: false,
      signInUsername: '',
      signInPassword: '',
      memberIndex: null,
      gobblerConnect: false,
      paidDues: false,
    });
  }

  signedInBody() {
    const { signInUsername, gobblerConnect, paidDues } = this.state;

    return (
      <div>
        <center className="fontPicker">
          <b>{`Hello ${signInUsername}!`}</b>
        </center>
        <hr />
        <div className="fontPicker">
          {(!gobblerConnect || !paidDues) && <div><b>You are still not a fully registered member until the following are completed:</b></div>}
          {(gobblerConnect && paidDues) && <div><b>You are officially a member!</b></div>}
          <br />
          <ul className="bolder">
            {!gobblerConnect && (
              <li className="pt-1">
                - Connect with us on&nbsp;
            <a href="https://gobblerconnect.vt.edu/organization/VESPA" target="_blank" rel="noopener noreferrer">
                  Gobbler Connect
            </a>
                .
            </li>
            )}
            {gobblerConnect && <div> - You are connected to GobblerConnect. <i className="fas fa-check" /></div>}
            <br />
            {!paidDues && <li> - Pay $10 membership fee via Venmo (@VESPAVT)</li>}
            {paidDues && <div> - You paid your due fees for this year. <i className="fas fa-check" /></div>}
          </ul>
          <div className="pt-3"><b>
            Remember to join our&nbsp;
                  <a href="https://discord.gg/kJEUD7G" target="_blank" rel="noopener noreferrer">discord</a>
            &nbsp;
                  <a href="https://discord.gg/kJEUD7G" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
            &nbsp;
            and fill out this&nbsp;
                  <a href="https://goo.gl/hZTwz1" target="_blank" rel="noopener noreferrer">form</a>
            .
            </b>
          </div>
          <br />
          <center>
            <Button className="btn-maroon" onClick={() => this.handleSignOut()}>Log out</Button>
          </center>
        </div>
      </div>
    );
  }

  render() {
    const { show, loginTab, signedIn, signInUsername } = this.state;
    return (
      <>
        <Navbar className="default-header fixed-top" expand="lg">
          <div className="sticky-header">
            <div className="paddingHeader">
              <div className="container">
                <div className="header-content d-flex justify-content-between align-items-center">
                  <a href="/" ><img src={logo} alt="Logo" className="halfWidth" /></a>
                  <div className="fromBottom">
                    <Link to="/About" className="white"> About </Link>
                    <Link to="/LeagueOfLegends" className="white"> League of Legends </Link>
                    <Link to="/CSGO" className="white"> CS:GO </Link>
                    <Link to="/Overwatch" className="white"> Overwatch </Link>
                    <Link to="/VentureGames" className="white"> Venture Games </Link>
                    <Link to="/Stream" className="white"> Stream </Link>
                    <Link to="/Events" className="white"> Events </Link>
                    {!signedIn && <button onClick={() => this.setState({ show: true })} className="white navButton"> Join Us! </button>}
                    {signedIn && <button onClick={() => this.setState({ show: true })} className="white navButton" >{signInUsername}</button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Navbar>

        <Modal className="loginModal" show={show} onHide={() => this.handleClose()}>
          <Modal.Body>
            <div>
              {!signedIn && (
                <div>
                  <center className="loginHeader">
                    <div className="ml-4 text-center">
                      <center>
                        <Button onClick={() => this.setState({ loginTab: 0, successfulRegister: false })} className={`btn-login ${loginTab === 0 ? 'btn-login-active' : ''}`}>
                          <i className="fas fa-user mr-1 left2Rem top2px" />
                          &nbsp;
                          Login
                      </Button>
                      </center>
                    </div>
                    &nbsp;
                    <div className="text-center">
                      <center>
                        <Button onClick={() => this.setState({ loginTab: 1 })} className={`btn-login ${loginTab === 1 ? 'btn-login-active' : ''}`}>
                          <i className="fas fa-user-plus mr-1 left2Rem top2px" />
                          &nbsp;
                          Register
                      </Button>
                      </center>
                    </div>
                  </center>
                  <br />
                  <br />
                </div>
              )}
              {!signedIn && this.getLogInBody()}
              {signedIn && this.signedInBody()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-maroon" onClick={() => this.handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default NavHeader;