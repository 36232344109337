import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from '../img/WhoWeAre.png';
import img2 from '../img/JoinUs2.png';


function About() {
  return (
    <div className="canScroll">
      <div className="aboutBody">
        <Container className="padding15">
          <Row>
            <Col className="paddingRight">
              <div className="centered">
                <img src={img1} alt="img1" className="aboutPic"/>
              </div>
            </Col>
            <Col className="paddingLeft">
              <div>
                <p className="section-title">Who We Are</p>
                <div className="fontPicker">
                  We are the Virginia E-Sports Association at Virginia Tech (VESPA). VESPA is a student organization looking to foster a community of students and alumni
                  that enjoy gaming as a hobby and bring them all under one banner. We organize and host a variety of events for both casual
                  and hardcore gamers including club meetings, giveaways, and tournaments. The club also oversees and recruits for collegiate level competitive teams for different E-Sports titles.
                </div>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="maroonBG">
        <Container className="padding15">
          <Row>
            <Col className="paddingRight">
              <div className="centered">
                <img src={img2} alt="img2" className="aboutPic"/>
              </div>
            </Col>
            <Col className="paddingLeft">
              <div>
                <p className="section-title-white">Why Join Us?</p>
                <div className="fontPicker whiteFont">
                  VESPA has currently over 100 registered members with 600 people in our discord channel and we are continuing to grow even larger!
                  What makes VESPA so attractive is the numerous amount of games we support, the long-lasting relationships you'll make, and the community involement.
                  We also support collegiate level teams so if you're looking to satisfy that competitve drive and potentially win scholarship prizing, 
                  this is the place for you!
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default About;
