import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import NavHeader from './components/NavHeader';
import League from './components/League';
import CSGO from './components/CSGO';
import Overwatch from './components/Overwatch';
import VentureGames from './components/VentureGames';
import Smite from './components/Smite';
import Stream from './components/Stream';
import Events from './components/Events';
import 'antd/dist/antd.css';
import './App.css';

const App = () => (
  <Switch>
    <div className="height100">
      <NavHeader />
      <Route exact path="/" component={Home} />
      <Route path="/About" component={About} />
      <Route path="/LeagueOfLegends" component={League} />
      <Route path="/CSGO" component={CSGO} />
      <Route path="/Overwatch" component={Overwatch} />
      <Route path="/VentureGames" component={VentureGames} />
      <Route path="/Smite" component={Smite} />
      <Route path="/Stream" component={Stream} />
      <Route path="/Events" component={Events} />
    </div>
  </Switch>
);

export default App;
